(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  "mobile": {
    "landscape": 896,
    "portrait": 700,
    "small": 480
  },
  "tablet": {
    "landscape": 1024,
    "portrait": 768
  },
  "notebook": {
    "small": 1366,
    "normal": 1550
  }
};

},{}],2:[function(require,module,exports){
'use strict';

require('./modules/lazyload.js');

require('./modules/modals.js');

require('./modules/forms.js');

require('./modules/masks.js');

require('./modules/spinner.js');

require('./modules/scroll.js');

require('./modules/menus.js');

require('./modules/search.js');

require('./modules/tabs.js');

require('./modules/sliders.js');

require('./modules/video.js');

require('./modules/spoilers.js');

require('./modules/compare.js');

require('./modules/history.js');

require('./modules/vuejs/vue.common.js');

require('./modules/vuejs/card-choose-offer.js');

},{"./modules/compare.js":3,"./modules/forms.js":4,"./modules/history.js":7,"./modules/lazyload.js":8,"./modules/masks.js":9,"./modules/menus.js":10,"./modules/modals.js":11,"./modules/scroll.js":12,"./modules/search.js":13,"./modules/sliders.js":14,"./modules/spinner.js":15,"./modules/spoilers.js":16,"./modules/tabs.js":17,"./modules/video.js":18,"./modules/vuejs/card-choose-offer.js":21,"./modules/vuejs/vue.common.js":22}],3:[function(require,module,exports){
'use strict';

$(function () {
  $(window).trigger('compareInit');
});

$(window).on('compareInit imageLoaded', function () {
  var $firstProduct = $('.compare-vitrine__product:first');
  var $productsList = $('.compare-vitrine__product');

  var headerMaxHeight = Math.max.apply(null, $.map($productsList, function (item) {
    var height = $(item).find('.vitrine-product').outerHeight();
    return height;
  }));

  $productsList.each(function setHeights() {
    $(this).find('.vitrine-product').css('minHeight', headerMaxHeight);
  });

  $firstProduct.find('.compare-vitrine__product-param').each(function paramHandler() {
    var $param = $(this);
    var index = $param.index();
    var contentMaxHeight = Math.max.apply(null, $.map($productsList, function (item) {
      var height = $(item).find('.compare-vitrine__product-param:eq(' + index + ')').height('auto').height();
      return height;
    }));
    $productsList.each(function setHeights() {
      $(this).find('.compare-vitrine__product-param:eq(' + index + ')').height(contentMaxHeight);
    });
  });
});

},{}],4:[function(require,module,exports){
'use strict';

var _globals = require('./globals.js');

// Selectric
// ---------

_globals.$DOCUMENT.on('initSelectric yiiListViewUpdated', function () {
  $('select:not(.no-default-selectric)').selectric({
    disableOnMobile: false,
    nativeOnMobile: true
  });
}).trigger('initSelectric');

// Checkboxes
// ----------

/* global autosize */

_globals.$BODY.on('change initCheckboxes', '.js-checkbox input', function (event) {
  var $inp = $(event.target);
  var $label = $inp.closest('.js-checkbox');

  if ($inp.prop('checked')) {
    $label.addClass('is-checked');
  } else {
    $label.removeClass('is-checked');
  }
});
$('.js-checkbox input').trigger('initCheckboxes');

// Radio buttons
// -------------

_globals.$BODY.on('change initRadio', '.js-radio input', function (event) {
  var $inp = $(event.target);
  var $group = $('[name="' + $inp.attr('name') + '"]');
  var $labels = $group.closest('.js-radio');
  var $selectedItem = $labels.find('input').filter(':checked').closest('.js-radio');

  $labels.removeClass('is-checked');
  $selectedItem.addClass('is-checked');
});
$('.js-radio input').trigger('initRadio');

// Rating stars
// -------------

$('.js-rating-stars .rating-stars__item').on('mouseenter', function handler() {
  var $this = $(this);

  $this.prevUntil().addClass('star--full');
  $this.addClass('star--full');
});

$('.js-rating-stars .rating-stars__item').on('mouseleave', function handler() {
  var $this = $(this);

  $this.prevUntil().removeClass('star--full');
  $this.removeClass('star--full');
});

$('.js-rating-stars .rating-stars__item').on('click', function handler(e) {
  e.preventDefault();
  var $this = $(this);

  $this.siblings().removeClass('star--fixed');
  $this.removeClass('star--fixed');

  $this.prevUntil().addClass('star--fixed');
  $this.addClass('star--fixed');

  $this.parent().next().val($this.index() + 1);
});

// Textarea autosize
// -----------------

$(function () {
  autosize($('textarea'));
});

// Touched inputs state
// --------------------

$('.form__input, .form__textarea').on('focus', function focusHandler() {
  $(this).closest('.form__field').addClass('is-touched');
}).on('blur', function blurHandler() {
  var $this = $(this);
  var value = $this.val();

  if (value !== '' && value != null && value !== '+7 (___) ___-__-__') return;

  $this.closest('.form__field').removeClass('is-touched');
}).on('inputs-init', function inputsInit() {
  var $this = $(this);
  var value = $this.val();

  if (value !== '' && value != null && value !== '+7 (___) ___-__-__') {
    $this.closest('.form__field').addClass('is-touched');
  }
}).trigger('inputs-init');

// Fileinputs
// ----------

_globals.$BODY.on('change', '.js-fileinput input', function changeHandler(event) {
  $(event.target).closest('.js-fileinput').parent().find('.js-fileinput-filename').text($(this).val());
});

// Filters

(function () {

  if (!$('.js-toggle-mobile-filters').length) return;

  var openedClass = 'filter-is-opened';

  var url = new URL(window.location);
  if (url.searchParams.get('filter')) {
    _globals.$BODY.addClass(openedClass);
  }

  $(document).on('click', '.js-toggle-mobile-filters', function (e) {
    e.preventDefault();

    _globals.$BODY.toggleClass(openedClass);

    if (_globals.$BODY.hasClass(openedClass)) {
      url = new URL(window.location);
      url.searchParams.set('filter', 'opened');
      window.history.pushState({}, '', url);
    } else {
      url = new URL(window.location);
      if (url.searchParams.get('filter')) {
        window.history.back();
      }
    }
  });

  $(document).on('click.close-mobile-filters', function (e) {
    var $target = $(e.target);
    if ($target.closest('.filters').length || $target.hasClass('js-toggle-mobile-filters')) return;
    if (_globals.$BODY.hasClass(openedClass)) {
      $('.js-toggle-mobile-filters').first().click();
    }
  });

  window.addEventListener('popstate', onPopstate);

  function onPopstate() {
    url = new URL(window.location);
    if (!url.searchParams.get('filter')) {
      _globals.$BODY.removeClass(openedClass);
    } else {
      _globals.$BODY.addClass(openedClass);
    }
  }
})();

$(document).on('click', '.js-toggle-filter-items', function toggleListHandler(e) {
  e.preventDefault();
  $(this).closest('fieldset').find('[data-list-root]').toggleClass('is-expanded');
});

},{"./globals.js":5}],5:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.$BODY = exports.$HTML = exports.$DOCUMENT = exports.$WINDOW = exports.HEADER_HEIGHT = exports.NOTEBOOK_WIDTH = exports.SMALL_NOTEBOOK_WIDTH = exports.TABLET_WIDTH = exports.PORTRAIT_TABLET_WIDTH = exports.LANDSCAPE_MOBILE_WIDTH = exports.MOBILE_WIDTH = exports.SMALL_MOBILE_WIDTH = exports.IS_TOUCH_DEVICE = exports.IS_IE = exports.IS_IOS = exports.IS_DESKTOP = exports.IS_MOBILE = undefined;

var _mediaQueriesConfigJson = require('../../media-queries-config.json.js');

var _mediaQueriesConfigJson2 = _interopRequireDefault(_mediaQueriesConfigJson);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// Const
// -----

var IS_MOBILE = exports.IS_MOBILE = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i) !== null;
var IS_DESKTOP = exports.IS_DESKTOP = !IS_MOBILE;
var IS_IOS = exports.IS_IOS = navigator.userAgent.match(/(iPad|iPhone|iPod)/g);
var IS_IE = exports.IS_IE = navigator.appVersion.indexOf('MSIE') !== -1 || navigator.userAgent.match(/Trident.*rv[ :]*11\./);
var IS_TOUCH_DEVICE = exports.IS_TOUCH_DEVICE = 'ontouchstart' in document;

var HTML = document.documentElement;

HTML.classList.remove('no-js');

if (IS_MOBILE) HTML.classList.add('is-mobile');
if (IS_DESKTOP) HTML.classList.add('is-desktop');
if (IS_IOS) HTML.classList.add('is-ios');
if (IS_IE) HTML.classList.add('is-ie');
if (IS_TOUCH_DEVICE) HTML.classList.add('is-touch-device');

var SMALL_MOBILE_WIDTH = exports.SMALL_MOBILE_WIDTH = _mediaQueriesConfigJson2.default.mobile.small;
var MOBILE_WIDTH = exports.MOBILE_WIDTH = _mediaQueriesConfigJson2.default.mobile.portrait;
var LANDSCAPE_MOBILE_WIDTH = exports.LANDSCAPE_MOBILE_WIDTH = _mediaQueriesConfigJson2.default.mobile.landscape;
var PORTRAIT_TABLET_WIDTH = exports.PORTRAIT_TABLET_WIDTH = _mediaQueriesConfigJson2.default.tablet.portrait;
var TABLET_WIDTH = exports.TABLET_WIDTH = _mediaQueriesConfigJson2.default.tablet.landscape;
var SMALL_NOTEBOOK_WIDTH = exports.SMALL_NOTEBOOK_WIDTH = _mediaQueriesConfigJson2.default.notebook.small;
var NOTEBOOK_WIDTH = exports.NOTEBOOK_WIDTH = _mediaQueriesConfigJson2.default.notebook.normal;

var HEADER_HEIGHT = exports.HEADER_HEIGHT = $('.header').height();

// Selectors
// ---------

var $WINDOW = exports.$WINDOW = $(window);
var $DOCUMENT = exports.$DOCUMENT = $(document);
var $HTML = exports.$HTML = $(document.documentElement);
var $BODY = exports.$BODY = $(document.body);

// Custom vh for mobile devices
// ----------------------------
// css example:
// height: calc(var(--vh, 1vh) * 100);

$(window).on('resize initVh', function () {
  var vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', vh + 'px');
}).trigger('initVh');

window.addEventListener('DOMContentLoaded', function () {
  $(window).on('load resize headerHeight', function () {
    var header = document.getElementsByClassName('header')[0];
    var bxPanel = document.getElementById('bx-panel') ? document.getElementById('bx-panel').clientHeight : 0;
    var hh = header.clientHeight + bxPanel;

    document.documentElement.style.setProperty('--hh', hh + 'px');
  });
});

// Trigger fonts loaded event
// --------------------------

if (document.fonts !== undefined) {
  document.fonts.onloadingdone = function fontsLoadedCallback() {
    $(document).trigger('fontsLoaded');
    $(window).trigger('fontsLoaded');
  };
}

},{"../../media-queries-config.json.js":1}],6:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IS_SMALL_MOBILE_WIDTH = exports.IS_LANDSCAPE_MOBILE_WIDTH = exports.IS_MOBILE_WIDTH = exports.IS_TABLET_WIDTH = exports.IS_SMALL_NOTEBOOK_WIDTH = exports.IS_NOTEBOOK_WIDTH = exports.IS_DESKTOP_WIDTH = exports.WINDOW_HEIGHT = exports.WINDOW_WIDTH = undefined;
exports.status = status;

var _globals = require('./globals.js');

/* eslint-disable import/no-mutable-exports */
var WINDOW_WIDTH = exports.WINDOW_WIDTH = window.innerWidth || _globals.$WINDOW.width();
var WINDOW_HEIGHT = exports.WINDOW_HEIGHT = _globals.$WINDOW.height();
/* eslint-enable import/no-mutable-exports */
_globals.$WINDOW.resize(function () {
  exports.WINDOW_WIDTH = WINDOW_WIDTH = window.innerWidth || _globals.$WINDOW.width();
  exports.WINDOW_HEIGHT = WINDOW_HEIGHT = _globals.$WINDOW.height();
});

var IS_DESKTOP_WIDTH = exports.IS_DESKTOP_WIDTH = function IS_DESKTOP_WIDTH() {
  return WINDOW_WIDTH > _globals.NOTEBOOK_WIDTH;
};
var IS_NOTEBOOK_WIDTH = exports.IS_NOTEBOOK_WIDTH = function IS_NOTEBOOK_WIDTH() {
  return WINDOW_WIDTH > _globals.SMALL_NOTEBOOK_WIDTH && WINDOW_WIDTH <= _globals.NOTEBOOK_WIDTH;
};
var IS_SMALL_NOTEBOOK_WIDTH = exports.IS_SMALL_NOTEBOOK_WIDTH = function IS_SMALL_NOTEBOOK_WIDTH() {
  return WINDOW_WIDTH > _globals.TABLET_WIDTH && WINDOW_WIDTH <= _globals.SMALL_NOTEBOOK_WIDTH;
};
var IS_TABLET_WIDTH = exports.IS_TABLET_WIDTH = function IS_TABLET_WIDTH() {
  return WINDOW_WIDTH >= _globals.PORTRAIT_TABLET_WIDTH && WINDOW_WIDTH <= _globals.TABLET_WIDTH;
};
var IS_MOBILE_WIDTH = exports.IS_MOBILE_WIDTH = function IS_MOBILE_WIDTH() {
  return WINDOW_WIDTH <= _globals.MOBILE_WIDTH;
};
var IS_LANDSCAPE_MOBILE_WIDTH = exports.IS_LANDSCAPE_MOBILE_WIDTH = function IS_LANDSCAPE_MOBILE_WIDTH() {
  return WINDOW_WIDTH <= _globals.LANDSCAPE_MOBILE_WIDTH;
};
var IS_SMALL_MOBILE_WIDTH = exports.IS_SMALL_MOBILE_WIDTH = function IS_SMALL_MOBILE_WIDTH() {
  return WINDOW_WIDTH <= _globals.SMALL_MOBILE_WIDTH;
};

// Response status
function status(response) {
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response;
}

},{"./globals.js":5}],7:[function(require,module,exports){
'use strict';

var $backLink = $('.js-history-back');
var currentUrl = window.location.href;

if ($backLink.length) {
  if (document.referrer === '') {
    $backLink.css('visibility', 'hidden');
  } else {
    $backLink.click(function () {
      window.history.back();
      setTimeout(function () {
        // if location was not changed in 100 ms, then there is no history back
        if (currentUrl === window.location.href) {
          window.close();
        }
      }, 100);
    });
  }
}

},{}],8:[function(require,module,exports){
'use strict';

(function () {

  var lazyloadImages = document.querySelectorAll('.js-lazy-pic');
  var imageIntersectionObserver = void 0;
  var completed = false;
  var lazyloadThrottleTimeout = void 0;

  document.addEventListener('DOMContentLoaded', function () {
    initLazyLoad();
    addImagesListUpdateHandlers();
  });

  function initLazyLoad() {
    if (supportsIntersectionObserver()) return observeImages();
    return observerFallback();
  }

  function supportsIntersectionObserver() {
    return 'IntersectionObserver' in window;
  }

  function observeImages() {
    createObserver();
    lazyloadImages.forEach(function (image) {
      imageIntersectionObserver.observe(image);
    });
  }

  function createObserver() {
    imageIntersectionObserver = new IntersectionObserver(function (entries) {
      processImages(entries);
    }, {
      rootMargin: '200px'
    });
  }

  function processImages(images) {
    images.forEach(function (item) {
      if (item.isIntersecting) {
        changeSrc(item.target);
        imageIntersectionObserver.unobserve(item.target);
      }
    });
  }

  function changeSrc(image) {
    if (!image.dataset.lazy) return;
    image.src = image.dataset.lazy;
    image.classList.remove('js-lazy-pic');
    setTimeout(function () {
      $(window).trigger('imageLoaded');
    }, 100);
  }

  function observerFallback() {
    document.addEventListener('scroll', lazyload);
    window.addEventListener('resize', lazyload);
    window.addEventListener('orientationChange', lazyload);
  }

  function lazyload() {
    lazyloadImages = document.querySelectorAll('.js-lazy-pic');

    if (lazyloadThrottleTimeout) {
      clearTimeout(lazyloadThrottleTimeout);
    }

    lazyloadThrottleTimeout = setTimeout(function () {
      lazyloadImages.forEach(function (image) {
        if (imageIsReached(image)) {
          changeSrc(image);
        }
      });
      if (noMoreImagesLeft()) {
        removeLazyLoadListeners();
        completed = true;
      }
    }, 20);
  }

  function imageIsReached(image) {
    var scrollTop = window.pageYOffset;
    return image.offsetTop < window.innerHeight + scrollTop;
  }

  function noMoreImagesLeft() {
    return lazyloadImages.length === 0;
  }

  function addImagesListUpdateHandlers() {
    $(document).on('yiiListViewUpdated', function () {
      addImagesToObserver();
    });
    $('body').on('iasRender', function () {
      addImagesToObserver();
    });
  }

  function addImagesToObserver() {
    lazyloadImages = document.querySelectorAll('.js-lazy-pic');
    if (supportsIntersectionObserver()) {
      lazyloadImages.forEach(function (image) {
        imageIntersectionObserver.observe(image);
      });
    } else if (completed) {
      observerFallback();
    }
  }

  function removeLazyLoadListeners() {
    document.removeEventListener('scroll', lazyload);
    window.removeEventListener('resize', lazyload);
    window.removeEventListener('orientationChange', lazyload);
  }
})();

},{}],9:[function(require,module,exports){
'use strict';

(function () {

  function initializePhoneMasks() {
    $('input[type="tel"]').each(function initMask() {
      var $input = $(this);
      var defaultPlaceholder = $input.attr('placeholder') || '';
      var defaultPlaceholderMask = '+7 (___) ___-__-__';
      var phoneMask = '+7 (000) 000-00-00';
      var pasted = false;

      $input.on('focus', function () {
        $input.attr('placeholder', defaultPlaceholderMask);
      }).on('blur', function () {
        $input.attr('placeholder', defaultPlaceholder);
      });

      $input.on('paste drop', function () {
        var isEmpty = !$input.val();
        var element = $input.get(0);
        var selectionLength = element.selectionEnd - element.selectionStart;

        if (!isEmpty && $input.val().length !== selectionLength) {
          return;
        }

        pasted = true;
      });

      $input.on('input', function (e) {
        var value = $input.val();

        if (value.startsWith('8')) {
          if (pasted && value.length !== 11) {
            pasted = false;
            return;
          }

          pasted = false;

          e.stopPropagation();

          value = value.replace('8', '+7');
          $input.val(value);
        }
      });

      $input.mask(phoneMask);
    });
  }

  $(window).on('initializePhoneMasks', initializePhoneMasks).trigger('initializePhoneMasks');
})();

},{}],10:[function(require,module,exports){
'use strict';

var _globals = require('./globals.js');

if (!_globals.IS_TOUCH_DEVICE) {
  _globals.$DOCUMENT.on('mouseenter', '.js-menu > a', function mouseenterHandler() {
    var $this = $(this);

    $this.closest('.js-menu').addClass('is-hovered');
  });

  _globals.$DOCUMENT.on('mouseleave', '.js-menu', function mouseenterHandler(ev) {
    var $this = $(this);
    var $target = $(ev.target);

    if ($target.closest('.js-menu').length !== 0) {
      $this.closest('.js-menu').removeClass('is-hovered');
    }
  });
}

if (!_globals.IS_TOUCH_DEVICE) {
  _globals.$DOCUMENT.on('mouseenter', '.js-menu .header__menu-list-item > a', function mouseenterHandler() {
    var $this = $(this);

    $this.closest('.header__menu-list-item').addClass('is-hovered');
  });

  _globals.$DOCUMENT.on('mouseleave', '.js-menu .header__menu-list-item', function mouseenterHandler(ev) {
    var $this = $(this);
    var $target = $(ev.target);

    if ($target.closest('.js-menu').length !== 0) {
      $this.closest('.header__menu-list-item').removeClass('is-hovered');
    }
  });
}

if (_globals.IS_TOUCH_DEVICE) {
  _globals.$DOCUMENT.on('click', '.js-menu .header__menu-list-item > a', function mouseenterHandler(e) {
    e.preventDefault();
    var $anchor = $(this);
    var $parent = $anchor.parent();
    var isHovered = $parent.hasClass('is-hovered');
    var link = $anchor.attr('href');

    if (isHovered && link) {
      window.location.href = link;
    } else {
      $parent.toggleClass('is-hovered');
    }
  });
}

$(function () {

  if (!$('.popup-menu').length) return;

  var openedClass = 'menu-is-opened';
  var initialUrl = new URL(window.location);
  var openedParam = initialUrl.searchParams.get('menu') === 'opened';

  if (openedParam) {
    _globals.$BODY.addClass(openedClass);
  }

  _globals.$DOCUMENT.on('click', '.js-toggle-popup-menu', function (e) {
    e.preventDefault();

    _globals.$BODY.toggleClass(openedClass);

    $('.popup-menu .is-hovered').removeClass('is-hovered');

    if (_globals.$BODY.hasClass(openedClass)) {
      var url = new URL(window.location);
      url.searchParams.set('menu', 'opened');
      window.history.pushState({}, '', url);

      _globals.$BODY.on('click.close-menu', function (ev) {
        var $target = $(ev.target);

        if ($target.closest('.popup-menu').length || $target.hasClass('js-toggle-popup-menu')) {
          return;
        }

        closeMenu(true);
      });
    } else {
      closeMenu(true);
    }
  });

  function closeMenu(back) {
    _globals.$BODY.removeClass(openedClass).off('click.close-menu');

    var url = new URL(window.location);
    if (url.searchParams.get('menu') && back) {
      window.history.back();
    }
  }

  window.addEventListener('popstate', onPopstate);

  function onPopstate() {
    if (_globals.$BODY.hasClass(openedClass)) {
      closeMenu(false);
    }
  }

  $('.js-toggle-mobile-menu-dropdown').click(function togglerClickHandler() {
    $(this).closest('ul').toggleClass('is-expanded');
  });

  // const hammerobject = new Hammer($('.popup-menu')[0], {});
  // hammerobject.on('swipeleft', function(e) {
  //   if ($BODY.hasClass(openedClass)) {
  //     closeMenu(true);
  //   }
  // });
});

},{"./globals.js":5}],11:[function(require,module,exports){
'use strict';

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; /* global initCardApp */

var _globals = require('./globals.js');

var OVERLAY_LOADER_DEFAULTS = {
  hideSelector: '.js-close-popup',
  fixedElements: '.js-fixed-element',
  onShow: function onShow() {
    _globals.$DOCUMENT.trigger('popupShown');
  }
};

_globals.$DOCUMENT.on('click.overlay-open', '.js-overlay', function clickHandler(e) {
  e.preventDefault();

  var $popup = $(this).attr('href');

  $.overlayLoader(true, _extends({
    node: $popup
  }, OVERLAY_LOADER_DEFAULTS));
});

_globals.$DOCUMENT.on('click.map-overlay-open', '.js-map-content-overlay', function clickHandler(e) {
  e.preventDefault();

  var $popup = $(this).attr('href');

  $.overlayLoader(true, _extends({
    node: $popup
  }, OVERLAY_LOADER_DEFAULTS, {
    onShow: function onShow() {
      _globals.$DOCUMENT.trigger('initMapTabSliders');
    }
  }));
});

_globals.$DOCUMENT.on('click.overlay-open', '.js-ajax-overlay', function clickHandler(e) {
  e.preventDefault();

  var $self = $(this);
  var $node = $($self.attr('href'));

  $.overlayLoader(true, _extends({
    node: $node
  }, OVERLAY_LOADER_DEFAULTS));

  $.ajax({
    type: $self.data('type'),
    url: $self.data('ajax-url'),
    data: parseAjaxOverlayParams($self.data('ajax-params')),
    timeout: 10000,
    success: function success(data) {
      $node.find('.js-ajax-data').replaceWith(data);
      initCardApp('#' + $node.find('.js-card-app').attr('id'));
      setTimeout(function () {
        $(document).trigger('ajaxPopupLoaded');
      }, 350);
    },
    error: function error() {}
  });
});

function parseAjaxOverlayParams(str) {
  if (str === undefined || str === '') return {};

  if ((typeof str === 'undefined' ? 'undefined' : _typeof(str)) === 'object') return str;

  var jsonParams = JSON.parse(str);
  if (jsonParams) return jsonParams;

  var obj = {};
  var array = str.split('&');

  array.forEach(function (value) {
    var a = value.split('=');
    /* eslint-disable prefer-destructuring */
    obj[a[0]] = a[1];
    /* eslint-enable prefer-destructuring */
    return true;
  });

  return obj;
}

// Autofocus
_globals.$DOCUMENT.on('overlayLoaderShow', function (e, $node) {
  $node.find('.js-autofocus-inp').focus();
});

/* eslint-disable */
// Tooltips
$(function () {
  // Только для мобилок
  $(document).on('click', '.text-hint, .hint-anchor', function (e) {
    e.stopPropagation();

    if (_globals.IS_TOUCH_DEVICE) {
      if ($(this).find('.data-hint-content').length) {
        var $hintOrigin = $(this).find('.data-hint-content');
        $hintOrigin.append($hintOrigin.data('content')).removeClass('data-hint-content');
      }

      if ($WINDOW.width() <= SMALL_MOBILE_WIDTH) {
        $('body').toggleClass('hint-is-opened');
      }

      $(this).toggleClass('is-hovered');
    }
  });

  if (_globals.IS_TOUCH_DEVICE) {
    $(document).on('click', '.text-hint .hint-content, .hint-anchor .hint-content', function (e) {
      e.stopPropagation();
    });
  }
  // ---------------------------

  // Только для десктопов
  if (!_globals.IS_TOUCH_DEVICE) {
    tippy.delegate('body', {
      target: '.text-hint:not([data-tooltip-on-click]), .hint-anchor:not([data-tooltip-on-click])',
      appendTo: function appendTo() {
        return document.body;
      },
      trigger: 'mouseenter focus',
      content: function content(reference) {
        if ($(reference).find('.data-hint-content').length) {
          var $hintOrigin = $(reference).find('.data-hint-content');
          return $hintOrigin.clone().append($hintOrigin.data('content')).html();
        }

        return $(reference).find('.text-hint__content').length ? $(reference).find('.text-hint__content').clone().html() : $(reference).find('.hint-content').clone().html();
      },
      allowHTML: true,
      interactive: true,
      touch: false,
      placement: 'right-start',
      onTrigger: function onTrigger(instance, event) {
        event.stopPropagation();
      },
      onUntrigger: function onUntrigger(instance, event) {
        event.stopPropagation();
      },

      popperOptions: {
        strategy: 'fixed',
        modifiers: [{
          name: 'flip',
          options: {
            fallbackPlacements: ['top', 'left']
          }
        }, {
          name: 'preventOverflow',
          options: {
            altAxis: true,
            tether: false
          }
        }]
      }
    });

    tippy.delegate('body', {
      target: '.text-hint[data-tooltip-on-click], .hint-anchor[data-tooltip-on-click]',
      appendTo: function appendTo() {
        return document.body;
      },
      trigger: 'click',
      placement: 'right-start',
      content: function content(reference) {
        if ($(reference).find('.data-hint-content').length) {
          var $hintOrigin = $(reference).find('.data-hint-content');
          return $hintOrigin.clone().append($hintOrigin.data('content')).html();
        }

        return $(reference).find('.text-hint__content').length ? $(reference).find('.text-hint__content').clone().html() : $(reference).find('.hint-content').clone().html();
      },
      allowHTML: true,
      interactive: true,
      touch: false,
      onTrigger: function onTrigger(instance, event) {
        event.stopPropagation();
      },
      onUntrigger: function onUntrigger(instance, event) {
        event.stopPropagation();
      },

      popperOptions: {
        strategy: 'fixed',
        modifiers: [{
          name: 'flip',
          options: {
            fallbackPlacements: ['right']
          }
        }, {
          name: 'preventOverflow',
          options: {
            // altAxis: true,
            tether: false
          }
        }]
      }
    });
  }
});
/* eslint-enable */

},{"./globals.js":5}],12:[function(require,module,exports){
'use strict';

var _globals = require('./globals.js');

// import { IS_MOBILE_WIDTH } from './helpers.js';

// Scroll to
// ---------

_globals.$DOCUMENT.on('click.scroll-to', '.js-scroll-to', function clickHandler(e) {
  e.preventDefault();

  var $link = $(this);
  var $elementToScroll = $($link.attr('href'));
  var speed = $link.data('speed') || 150;
  var offset = $link.data('offset') || 0;

  if ($elementToScroll.closest('.js-tabs').length > 0) {
    $elementToScroll.closest('.js-tabs').each(function searchTab() {
      $(this).find('.tabs-nav li:nth-child(2) a').click();
    });

    _globals.$WINDOW.scrollTo($elementToScroll.closest('.js-tabs'), { duration: speed, offset: offset });
    return;
  }

  _globals.$WINDOW.scrollTo($elementToScroll, { duration: speed, offset: offset });
});

// Scrolling to top
// ----------------

$(function () {
  var $goTopButton = $('.go-top-btn');
  var $goTopButtonWrapper = $('.go-top-btn-wrapper');

  $goTopButton.click(function (e) {
    e.preventDefault();
    _globals.$WINDOW.scrollTo(0, 200);
  });

  _globals.$WINDOW.scroll(function () {
    var scrollTop = _globals.$WINDOW.scrollTop();
    $(window).trigger('headerHeight');
    if (scrollTop > 0) {
      $goTopButtonWrapper.addClass('is-visible');
    } else {
      $goTopButtonWrapper.removeClass('is-visible');
    }
  });

  var $goTopButtonHide = $('.js-go-top-btn-hide');

  $goTopButtonHide.click(function () {
    $goTopButtonHide.closest('.go-top-btn-wrapper').toggleClass('is-expanded');
  });
});

(function () {
  var scrollPos = $(window).scrollTop();
  var scrollCheckingTimeout = void 0;

  _globals.$WINDOW.scroll(function () {
    if (scrollCheckingTimeout) {
      clearTimeout(scrollCheckingTimeout);
    }
    scrollCheckingTimeout = setTimeout(function () {
      return checkScrollPos();
    }, 0);
  });

  function checkScrollPos() {
    var newScrollPos = _globals.$WINDOW.scrollTop();
    if (newScrollPos > $('.header').height()) {
      _globals.$BODY.addClass('scrolledFromTop');
      $('.js-menu li').removeClass('is-hovered'); // refs #72387
      if (newScrollPos > scrollPos) {
        _globals.$BODY.addClass('scrolledDown').removeClass('scrolledUp');
      } else if (newScrollPos < scrollPos) {
        _globals.$BODY.addClass('scrolledUp').removeClass('scrolledDown');
      }
    } else {
      _globals.$BODY.removeClass('scrolledFromTop scrolledDown');
    }
    scrollPos = newScrollPos;
  }
})();

},{"./globals.js":5}],13:[function(require,module,exports){
'use strict';

var _globals = require('./globals.js');

// City search

$(function () {
  var $inp = $('.js-search-input');

  $inp.keyup(function () {
    var $inpValue = $inp.val();
    var $items = $('.js-search-body .item');
    var target = '.js-search-target';

    $items.each(function citySearch(index, value) {
      var $target = $(value).find(target).text().toLowerCase();

      if (!$target.includes($inpValue.toLowerCase())) {
        $(this).fadeOut();
      } else {
        $(this).fadeIn();
      }
    });
  });

  $(document).on('click', '.js-filter-letter', function (e) {
    e.preventDefault();
    var $this = $(e.target);
    var $items = $('.js-search-body .item');
    var target = '.js-search-target';
    var filterValue = $this.data('letter').toLowerCase();

    if ($this.hasClass('is-active')) {
      $items.fadeIn();
      $this.removeClass('is-active');
      return;
    }

    $this.siblings().removeClass('is-active');
    $this.addClass('is-active');

    $items.each(function filterByLetter(index, value) {
      var $target = $(value).find(target).text().toLowerCase()[0];

      if ($target[0] !== filterValue) {
        $(this).fadeOut();
      } else {
        $(this).fadeIn();
      }
    });
  });

  $(document).on('click', '.js-letter-filter-clear', function (e) {
    e.preventDefault();

    $('.js-filter-letter').removeClass('is-active');
    $('.js-search-body .item').fadeIn();
  });
});

_globals.$DOCUMENT.on('click', function (event) {
  var $predictive = $('.js-search-predictive');
  var $target = $(event.target);

  if (!$target.hasClass('js-search-predictive') && !$target.parents().hasClass('js-search-predictive')) {
    _globals.$BODY.removeClass('search-is-opened');
    $predictive.fadeOut();
  }
});

},{"./globals.js":5}],14:[function(require,module,exports){
'use strict';

var _globals = require('./globals.js');

$('.js-slideshow').each(function createSlider() {
  var $this = $(this);

  $this.slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    arrows: true,
    dots: false,
    touchThreshold: 100
  });
});

$(function () {
  $(document).on('init-home-slider yiiListViewUpdated', function () {
    initHomepageSlider();
  }).trigger('init-home-slider');

  // $('body').on('iasRender', () => { initHomepageSlider(); });

  function initHomepageSlider() {
    $('.js-home-slider').each(function createSlider() {
      var $this = $(this);
      var $parent = $this.closest('[data-slider-root]');
      var $controls = $parent.find('.js-home-slider-controls');
      var $status = $parent.find('[data-slider-counter]');

      $this.on('init reInit beforeChange', function (event, slick, currentSlide, nextSlide) {
        var i = (nextSlide || 0) + 1;
        var count = '' + slick.slideCount;

        /* eslint-disable */
        if (count < 10) {
          if (i < 10) {
            $status.html('<span>0' + i + '</span>&nbsp;/&nbsp;0' + count);
          } else {
            $status.html('<span>' + i + '</span>&nbsp;/&nbsp;0' + count);
          }
        } else {
          if (i < 10) {
            $status.html('<span>0' + i + '</span>&nbsp;/&nbsp;' + count);
          } else {
            $status.html('<span>' + i + '</span>&nbsp;/&nbsp;' + count);
          }
        }
        /* eslint-enable */
      });

      $this.slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        arrows: false,
        dots: true,
        appendDots: $controls,
        touchThreshold: 100,
        autoplay: true,
        autoplaySpeed: 3000
      });
    });
  }
});

$('.js-image-gallery').each(function createSlider() {
  var $this = $(this);

  $this.slick({
    slidesToShow: 2.5,
    slidesToScroll: 1,
    infinite: false,
    arrows: true,
    dots: false,
    touchThreshold: 100,
    responsive: [{
      breakpoint: _globals.MOBILE_WIDTH + 1,
      settings: {
        slidesToShow: 1.5,
        slidesToScroll: 1
      }
    }]
  });
});

$('.js-about-slider').each(function createSlider() {
  var $this = $(this);

  $this.slick({
    slidesToShow: 2.1,
    slidesToScroll: 1,
    infinite: false,
    arrows: false,
    dots: false,
    touchThreshold: 100,
    responsive: [{
      breakpoint: _globals.MOBILE_WIDTH + 1,
      settings: {
        slidesToShow: 1.2,
        slidesToScroll: 1
      }
    }]
  });
});

$('.js-principles-slider').each(function createSlider() {
  var $this = $(this);

  $this.slick({
    slidesToShow: 3.5,
    slidesToScroll: 1,
    infinite: false,
    arrows: false,
    dots: false,
    touchThreshold: 100,
    responsive: [{
      breakpoint: 1280 + 1,
      settings: {
        slidesToShow: 2.5,
        slidesToScroll: 1
      }
    }, {
      breakpoint: _globals.MOBILE_WIDTH + 1,
      settings: {
        slidesToShow: 1.5,
        slidesToScroll: 1
      }
    }]
  });
});

$('.js-news-slider').each(function createSlider() {
  var $this = $(this);
  var $parent = $this.closest('[data-slider-root]');
  var $controls = $parent.find('.js-news-slider-controls');
  var $status = $parent.find('[data-slider-counter]');

  $this.on('init reInit beforeChange', function (event, slick, currentSlide, nextSlide) {
    var i = (nextSlide || 0) + 1;
    var count = '' + slick.slideCount;

    if (_globals.$WINDOW.width() > _globals.MOBILE_WIDTH) {
      $status.html('<span>' + i + '</span>/' + count);
    }
  });

  $this.on('init reInit', function () {
    $this.find('.slick-dots li').addClass('dot');
  });

  $this.slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: false,
    arrows: true,
    appendArrows: $controls,
    dots: false,
    appendDots: $controls,
    touchThreshold: 5,
    responsive: [{
      breakpoint: _globals.TABLET_WIDTH + 1,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    }, {
      breakpoint: _globals.MOBILE_WIDTH + 1,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: false
      }
    }]
  });
});

$('.js-card-slider').each(function createSlider() {
  var $slider = $(this);
  var $parent = $slider.closest('[data-slider-root]');
  // const $controls = $parent.find('.js-slideshow-controls');
  var $thumbs = $parent.find('.js-card-slider-thumbs');
  var withThumbs = $thumbs.length;
  var $dots = $parent.find('.js-gallery-dots');

  $slider.on('init reInit', function () {
    $slider.find('.slick-dots li').addClass('dot');
  });

  $slider.on('init afterChange', function () {
    var $scroll = $dots.find('.slick-dots');
    if (!$scroll.length) return;

    $scroll.scrollTo($dots.find('.slick-active'), {
      duration: 300,
      offset: -$dots.width() / 2
    });
    setTimeout(function () {
      if ($scroll.scrollLeft() === 0) {
        $dots.addClass('is-at-start');
      } else {
        $dots.removeClass('is-at-start');
      }
      if (parseInt($scroll.scrollLeft(), 10) === parseInt($scroll[0].scrollWidth - $scroll.width(), 10)) {
        $dots.addClass('is-at-end');
      } else {
        $dots.removeClass('is-at-end');
      }
    }, 200);
  });

  $slider.slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: false,
    pauseOnHover: true,
    asNavFor: withThumbs ? $thumbs : null,
    waitForAnimate: false,
    responsive: [{
      breakpoint: _globals.TABLET_WIDTH + 1,
      settings: {
        adaptiveHeight: true
      }
    }, {
      breakpoint: _globals.SMALL_MOBILE_WIDTH + 1,
      settings: {
        appendDots: $dots,
        adaptiveHeight: true
      }
    }]
  });
});

$('.js-card-slider-thumbs').each(function createSlider() {
  var _this = this;

  var $slider = $(this);
  var $parent = $slider.closest('[data-slider-root]');

  $slider.on('afterChange', function () {
    $(_this).find('.slick-list').scrollTo('.slick-slide.slick-current');
  });

  $slider.on('init reInit', function () {
    $slider.find('.slick-dots li').addClass('dot');
  });

  $slider.slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: false,
    dots: false,
    arrows: false,
    asNavFor: $parent.find('.js-card-slider'),
    focusOnSelect: true,
    verticalSwiping: true,
    responsive: [{
      breakpoint: _globals.TABLET_WIDTH + 1,
      settings: 'unslick'
    }]
  });
});

$(function () {
  $(document).on('reviews-gallery-init tab-changed yiiListViewUpdated', function () {
    initGallery();
  }).trigger('reviews-gallery-init');

  function initGallery() {
    $('.js-reviews-slider').each(function createSlider() {
      var $this = $(this);

      if ($this.hasClass('slick-initialized')) {
        if ($this.find('.slick-slide').width() > 1) return;
        $this.slick('unslick');
      }

      $this.on('init reInit', function () {
        $this.find('.slick-dots li').addClass('dot');
      });

      $this.slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: false,
        arrows: false,
        dots: true,
        touchThreshold: 10,
        responsive: [{
          breakpoint: _globals.TABLET_WIDTH + 1,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        }, {
          breakpoint: _globals.MOBILE_WIDTH + 1,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }]
      });
    });
  }
});

$('.js-advantages-slider').each(function createSlider() {
  var $this = $(this);

  $this.on('init reInit', function () {
    $this.find('.slick-dots li').addClass('dot');
  });

  $this.slick({
    slidesToShow: 6,
    slidesToScroll: 6,
    infinite: false,
    arrows: true,
    dots: false,
    touchThreshold: 10,
    responsive: [{
      breakpoint: _globals.SMALL_NOTEBOOK_WIDTH + 1,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 5,
        dots: true,
        arrows: false
      }
    }, {
      breakpoint: _globals.LANDSCAPE_MOBILE_WIDTH + 1,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
        dots: true,
        arrows: false
      }
    }, {
      breakpoint: _globals.MOBILE_WIDTH + 1,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        dots: true,
        arrows: false
      }
    }, {
      breakpoint: _globals.SMALL_MOBILE_WIDTH + 1,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        dots: true,
        arrows: false
      }
    }, {
      breakpoint: 350 + 1,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: false
      }
    }]
  });
});

$(function () {
  $(document).on('compare-gallery-init tab-changed yiiListViewUpdated initCompareSlider', function () {
    initGallery();
  }).trigger('compare-gallery-init');

  function initGallery() {
    $('.js-compare-slider').each(function createSlider() {
      var $this = $(this);
      var $parent = $this.closest('[data-slider-root]');
      var $controls = $parent.find('.js-compare-controls');

      $this.slick({
        slidesToShow: 4,
        slidesToScroll: 4,
        infinite: false,
        dots: false,
        arrows: true,
        appendArrows: $controls,
        touchThreshold: 100,
        responsive: [{
          breakpoint: _globals.SMALL_NOTEBOOK_WIDTH + 1,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        }, {
          breakpoint: _globals.LANDSCAPE_MOBILE_WIDTH + 1,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        }, {
          breakpoint: _globals.SMALL_MOBILE_WIDTH + 1,
          settings: {
            slidesToShow: 1.2,
            slidesToScroll: 1
          }
        }]
      });
    });
  }
});

$('.js-vitrine-slider').each(function createSlider() {
  var $this = $(this);
  var $parent = $this.closest('[data-slider-root]');
  var $controls = $parent.find('.js-relevant-controls');
  var $dots = $parent.find('.js-gallery-dots');

  $this.on('init reInit', function () {
    $this.find('.slick-dots li').addClass('dot');
  });

  $this.on('init afterChange', function () {
    var $scroll = $dots.find('.slick-dots');
    if (!$scroll.length) return;

    $scroll.scrollTo($dots.find('.slick-active'), {
      duration: 300,
      offset: -$dots.width() / 3
    });
    setTimeout(function () {
      if ($scroll.scrollLeft() === 0) {
        $dots.addClass('is-at-start');
      } else {
        $dots.removeClass('is-at-start');
      }
      if (parseInt($scroll.scrollLeft(), 10) === parseInt($scroll[0].scrollWidth - $scroll.width(), 10)) {
        $dots.addClass('is-at-end');
      } else {
        $dots.removeClass('is-at-end');
      }
    }, 200);
  });

  $this.slick({
    slidesToShow: 4,
    slidesToScroll: 4,
    infinite: false,
    dots: false,
    appendDots: $dots,
    arrows: true,
    appendArrows: $controls,
    touchThreshold: 100,
    responsive: [{
      breakpoint: _globals.SMALL_NOTEBOOK_WIDTH + 1,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3
      }
    }, {
      breakpoint: _globals.LANDSCAPE_MOBILE_WIDTH + 1,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        dots: true,
        arrows: false
      }
    }, {
      breakpoint: _globals.SMALL_MOBILE_WIDTH + 1,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: false
      }
    }]
  });
});

$('.js-brand-sertificates-slider').each(function createSlider() {
  var $this = $(this);

  $this.slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: false,
    arrows: true,
    dots: false,
    touchThreshold: 10,
    responsive: [{
      breakpoint: _globals.SMALL_NOTEBOOK_WIDTH + 1,
      settings: {
        slidesToShow: 3
      }
    }, {
      breakpoint: _globals.MOBILE_WIDTH + 1,
      settings: {
        slidesToShow: 2
      }
    }, {
      breakpoint: _globals.SMALL_MOBILE_WIDTH + 1,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }]
  });
});

$('.js-brand-gallery-slider').each(function createSlider() {
  var $this = $(this);
  var $parent = $this.closest('[data-slider-root]');
  var $controls = $parent.find('.js-news-slider-controls');

  $this.slick({
    slidesToShow: 2.5,
    slidesToScroll: 1,
    infinite: false,
    arrows: true,
    appendArrows: $controls,
    dots: false,
    touchThreshold: 10,
    responsive: [{
      breakpoint: _globals.MOBILE_WIDTH + 1,
      settings: {
        slidesToShow: 1.5
      }
    }, {
      breakpoint: _globals.SMALL_MOBILE_WIDTH + 1,
      settings: {
        slidesToShow: 1
      }
    }]
  });
});

},{"./globals.js":5}],15:[function(require,module,exports){
'use strict';

var _globals = require('./globals.js');

_globals.$DOCUMENT.on('mousedown.js-spinner', '.js-spinner-down, .js-spinner-up', function mousedownHandler() {
  var $spinnerControl = $(this);
  var $input = $spinnerControl.siblings('input');
  var step = $input.data('step') ? $input.data('step') : 1;
  var mayBeZero = $input.data('zero') ? $input.data('zero') : false;
  var value = parseInt($input.val(), 10);
  var incTimeout = void 0;
  var incInterval = void 0;
  var decTimeout = void 0;
  var decInterval = void 0;

  $spinnerControl.on('mouseup.js-spinner', clearAll).on('mouseleave.js-spinner', $spinnerControl, clearAll);

  if ($spinnerControl.hasClass('js-spinner-down')) {
    decVal();decTimeout = setTimeout(function () {
      decInterval = setInterval(decVal, 70);
    }, 300);
  }

  if ($spinnerControl.hasClass('js-spinner-up')) {
    incVal();incTimeout = setTimeout(function () {
      incInterval = setInterval(incVal, 70);
    }, 300);
  }

  function incVal() {
    if ($.isMouseLoaderActive()) return;

    value += step;
    $input.val(value).change().trigger('input');
    $input[0].dispatchEvent(new Event('input'));
  }

  function decVal() {
    if ($.isMouseLoaderActive()) return;

    if (mayBeZero) {
      if (value >= step) {
        value -= step;
        $input.val(value).change();
        $input[0].dispatchEvent(new Event('input'));
      }
    } else if (value > step) {
      value -= step;
      $input.val(value).change();
      $input[0].dispatchEvent(new Event('input'));
    }
  }

  function clearAll() {
    clearTimeout(decTimeout);clearInterval(decInterval);
    clearTimeout(incTimeout);clearInterval(incInterval);
  }
});

_globals.$DOCUMENT.on('keydown', '.js-spinner input', function (e) {
  if (isKeyToSkip(e.keyCode, e.ctrlKey)) {
    return e.keyCode;
  }
  if (isNotADigitKey(e.keyCode)) {
    e.preventDefault();
  }
  return e.keyCode;
});

_globals.$DOCUMENT.on('keyup paste', '.js-spinner input', function keyupHandler() {
  var $input = $(this);
  var mayBeZero = $input.data('zero') ? $input.data('zero') : false;

  if ($input.val() === 0 || $input.val() === '') {
    if (!mayBeZero) {
      $input.val(1);
    } else {
      $input.val(0);
    }
  }
});

function isKeyToSkip(keyCode, ctrlKey) {
  return isDelKey(keyCode) || isBackspaceKey(keyCode) || isTabKey(keyCode) || isEscKey(keyCode) || isSelectAllHotkeys(keyCode, ctrlKey) || isPositioningKey(keyCode);
}

function isDelKey(keyCode) {
  return keyCode === 46;
}

function isBackspaceKey(keyCode) {
  return keyCode === 8;
}

function isTabKey(keyCode) {
  return keyCode === 9;
}

function isEscKey(keyCode) {
  return keyCode === 27;
}

function isSelectAllHotkeys(keyCode, ctrlKey) {
  // Ctrl+A
  return keyCode === 65 && ctrlKey === true;
}

function isPositioningKey(keyCode) {
  // arrows or home / end
  return keyCode >= 35 && keyCode <= 39;
}

function isNotADigitKey(keyCode) {
  // including numpad keys
  return (keyCode < 48 || keyCode > 57) && (keyCode < 96 || keyCode > 105);
}

},{"./globals.js":5}],16:[function(require,module,exports){
'use strict';

var _helpers = require('./helpers.js');

$(function () {

  $('.js-spoiler').each(function spoilerHandler() {
    var $this = $(this);
    $(window).on('spoilersInit resize fontsLoaded', function () {
      var isExpanded = $this.hasClass('is-expanded');
      var isInsideHiddenTab = $this.closest('.tabs-panel:not(.is-active)').length;
      var isInsideCollapsedSpoiler = $this.closest('.js-spoiler:not(.is-expanded)').length;
      var isInsideFilter = $this.closest('#filter-aside').length;
      var $el = $this.find('.js-spoiler-body').first();
      if ($el.length < 1) return;
      var el = $el[0];
      $('.js-spoiler-body').each(function addTempState() {
        $(this)[0].style.setProperty('transition', 'none');
        $(this).find('ul').addClass('is-expanded-for-calc');
      });
      if (!isExpanded) $this.addClass('is-expanded-for-calc');
      if (isInsideCollapsedSpoiler) $this.closest('.js-spoiler').addClass('is-expanded');
      if (isInsideHiddenTab) $this.closest('.tabs-panel').addClass('is-active');
      if (isInsideFilter) $('#filter-aside')[0].style.setProperty('display', 'block');
      el.style.setProperty('--max-height', '');
      el.style.setProperty('--max-height', $el.outerHeight() + 'px');
      if (!isExpanded) $this.removeClass('is-expanded-for-calc');
      if (isInsideCollapsedSpoiler) $this.closest('.js-spoiler').removeClass('is-expanded');
      if (isInsideHiddenTab) $this.closest('.tabs-panel').removeClass('is-active');
      if (isInsideFilter) $('#filter-aside')[0].style.setProperty('display', '');
      $('.js-spoiler-body').each(function removeTempState() {
        $(this)[0].style.setProperty('transition', '');
        $(this).find('ul').removeClass('is-expanded-for-calc');
      });
    });

    if ($this.hasClass('js-save-spoiler-state')) {
      if (localStorage.getItem('SPOILER_STATE_' + $this.attr('id')) === 'true') {
        $this.addClass('is-expanded');
      }
    }
  });
  setTimeout(function () {
    $(window).trigger('spoilersInit');
  }, 0);

  $(document).on('click', '.js-toggle-spoiler', function toggleClickHandler(e) {
    e.preventDefault();

    var $this = $(this);
    var $spoiler = $this.closest('.js-spoiler');
    var scrollAfter = $this.hasClass('js-scroll-to-closed-spoiler');
    var saveState = $spoiler.hasClass('js-save-spoiler-state');
    $spoiler.toggleClass('is-expanded');
    if (scrollAfter && !$spoiler.hasClass('is-expanded')) {
      $(window).scrollTo($spoiler, { duration: 200, offset: -180 });
    }
    setTimeout(function () {
      $(window).trigger('spoilerToggled');
    }, 250);
    if (saveState) {
      localStorage.setItem('SPOILER_STATE_' + $spoiler.attr('id'), $spoiler.hasClass('is-expanded'));
    }
  });

  $('.js-toggle-mobile-spoiler').click(function mobileToggleClickHandler() {
    if (!(0, _helpers.IS_LANDSCAPE_MOBILE_WIDTH)()) return;
    $(this).closest('.js-spoiler').toggleClass('is-expanded');
    setTimeout(function () {
      $(window).trigger('spoilerToggled');
    }, 250);
  });

  $(document).on('click', '.js-toggle-share', function toggleShareSpoiler(e) {
    e.preventDefault();

    var $toggler = $(this);
    var url = $toggler.data('url');
    var $spoiler = $toggler.closest('.js-share');
    var $inp = $spoiler.find('.js-share-input');
    var $popup = $spoiler.find('.js-share-body');
    var $socials = $popup.find('.js-share-item');
    var productFullUrl = window.location.origin + url;

    if ($(window).width() > 700) {
      $spoiler.toggleClass('is-expanded');
    } else {
      $.overlayLoader(true, {
        node: $popup,
        hideSelector: '.js-toggle-share',
        fixedElements: '.js-fixed-element'
      });
    }

    $inp.val(productFullUrl);

    $socials.each(function (index, element) {
      $(element).attr('href', $(element).data('href') + productFullUrl);
    });
  });

  $(document).on('click', '.js-share-btn', function copyShareLink() {
    var $this = $(this);
    var copyText = document.querySelector('.js-share-input');

    copyText.select();
    document.execCommand('copy');
    $this.text('Скопировано');
  });

  $(document).on('click', '.js-brand-top__more', function openText(e) {
    e.preventDefault();
    var $this = $(this);
    var $textContainer = document.querySelector('.js-brand-top__text');

    $this.siblings($textContainer).toggleClass('is-open');
  });
});

},{"./helpers.js":6}],17:[function(require,module,exports){
'use strict';

$('.js-tabs .tabs-nav li a').click(function clickHandler(e) {
  e.preventDefault();

  var $this = $(this);
  var $panel = $($this.attr('href'));

  $this.closest('li').addClass('is-active').siblings().removeClass('is-active');
  $panel.closest('.tabs').find('.tabs-panel').removeClass('is-active').hide();
  $panel.fadeIn(function () {
    return $(document).trigger('tab-changed');
  }).addClass('is-active');
});

$(function () {
  $('.js-tabs').each(function handler() {
    $(this).find('.tabs-nav li:first a').click();
  });
});

$('.js-toggle-map-view').click(function handler(e) {
  e.preventDefault();

  var $this = $(this);

  $this.addClass('is-active').siblings().removeClass('is-active');
  $($this.attr('href')).stop(true, true).fadeIn();
  $($this.siblings().attr('href')).stop(true, true).hide();

  if ($this.attr('href') === '#map-view') {
    $('.map-section__mobile-content-link').show();
  } else {
    $('.map-section__mobile-content-link').hide();
  }
});

},{}],18:[function(require,module,exports){
'use strict';

$(function () {
  $(document).on('click', '.js-play-video', function replaceVideo(e) {
    e.preventDefault();
    var $this = $(this);
    var $videoLink = $this.prop('href');
    var $video = $('<iframe width="100%" height="100%" src="' + $videoLink + '"\n    frameborder="0" allowfullscreen></iframe>');
    $this.find('img').replaceWith($video);
  });
});

},{}],19:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
/* eslint-disable */

/**
 * Пирмер подключения в шаблоне:
 * <?php $offers = $model->getOffersWithParameter(false) ?>
 * <offers-list-component :offers-list='<?=json_encode($offers)?>' inline-template>
 *  <div>
 *    <label class="item js-radio" v-bind:class="getClass(offer)" v-bind:data-size="offer.text"
 *           v-for="offer in offersList" @click="click(offer)">{{offer.text}}</label>
 *  </div>
 * </offers-list-component>
 */
/**
 * @type {{props: {selectedId: {type: NumberConstructor}, offersList: {type: ArrayConstructor}}, methods: {isActive(*): *}}}
 */
var OffersListComponent = {
  data: function data() {
    return {
      expanded: false
    };
  },

  props: {
    selectedId: {
      type: Number
    },
    offersList: {
      type: Array
    },
    selectedClass: {
      type: String,
      default: 'is-checked'
    },
    disabledClass: {
      type: String,
      default: 'disabled'
    }
  },
  methods: {
    isActive: function isActive(id) {
      return this.$root.offer.id == id;
    },
    click: function click(offer) {
      if (offer.disabled) return;

      this.$root.offer = offer;
    },
    getClass: function getClass(offer) {
      var classArray = [];

      if (this.isActive(offer.id)) classArray.push(this.selectedClass);

      if (offer.disabled) classArray.push(this.disabledClass);

      return classArray.join(' ');
    }
  }
};

exports.default = OffersListComponent;
/* eslint-enable */

},{}],20:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
/* eslint-disable */
/**
 * Пример подключения в шаблоне
 * <price-component :price="this.offer.price" :old-price="this.offer.oldPrice" inline-template>
 * <div class="product-card__price-group">
 *   <div class="product-card__old-price" v-if="oldPrice">
 *     <span v-html="oldPriceFormatted"></span>
 *     <div class="product-card__discount" v-html="economyPercentFormatted"></div>
 *   </div>
 *   <div class="product-card__price" v-html="priceFormatted"><?php echo PriceHelper::price($model->getPrice(), ' <span class="currency"><span>₽</span></span>');?></div>
 * </div>
 * </price-component>
 */

/**
 * @type {{props: {price: {type: NumberConstructor}, oldPrice: {type: NumberConstructor, default: number}, currencySuffix: {type: StringConstructor, default: string}, emptyPriceText: {type: StringConstructor, default: string}, priceAmountText: {type: StringConstructor, default: string}}, computed: {economyPercent(): (number|*), priceFormatted(): *, oldPriceFormatted(): *, economyPercentFormatted(): string}}}
 */
var PriceComponent = {
  props: {
    priceBase: { // цена за 1 единицу товара
      type: Number
    },
    oldPriceBase: { // старая цена за 1 единицу товара
      type: Number,
      default: 0
    },
    currencySuffix: {
      type: String,
      default: ' <span class="currency"><span>₽</span></span>'
    },
    emptyPriceText: {
      type: String,
      default: ' <span class="call">Звоните!</span>'
    },
    rate: { // мин едеиниц (цена задана ни мин единицу)
      type: Number,
      default: 1
    },
    minAmount: { // Минимальное число допустимых для заказа единиц
      type: Number,
      default: 1
    },
    priceAmountText: { // колличество в единице продаже
      type: String,
      default: ''
    },
    resetAmount: { // колличество в единице продаже
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      amount: this.minAmount,
      rateInner: this.rate * this.minAmount,
      reserve: 0,
      reserveRateBackup: 0
    };
  },

  computed: {
    price: function price() {
      return this.round(this.priceBase * this.rate);
    },
    oldPrice: function oldPrice() {
      return this.round(this.oldPriceBase * this.rate);
    },
    priceSum: function priceSum() {
      return this.round(this.priceBase * this.rate * this.getAmount());
    },
    minValue: function minValue() {
      return this.minAmount;
    },
    oldPriceSum: function oldPriceSum() {
      return this.round(this.oldPriceBase * this.rate * this.getAmount());
    },
    economySum: function economySum() {
      if (this.oldPriceSum > 0 && this.oldPriceSum > this.priceSum) return this.oldPriceSum - this.priceSum;

      return 0;
    },

    rateCalc: {
      get: function get() {
        return isNaN(this.rateInner) ? "" : this.rateInner;
      },
      set: function set(newValue) {
        this.setRate(newValue);
        this.resetReserve();
      }
    },
    amountInput: {
      get: function get() {
        return this.getAmount();
      },
      set: function set(newValue) {
        this.amount = newValue;
        this.rateInner = this.smartRound(this.getAmount() * this.rate);
        this.resetReserve();
      }
    },
    needUse: function needUse() {
      return this.smartRound(this.amount * this.rate);
    }
    /*    economyPercent() {
          if( !this.oldPrice )
            return 0;
          var percent = (this.price - this.oldPrice) * 100 / this.oldPrice;
          return Math.ceil(percent);
        },*/

  },
  watch: {
    reserve: function reserve() {
      if (this.reserveRateBackup == 0) this.reserveRateBackup = this.rateCalc;

      if (this.reserve == 0 && this.reserveRateBackup > 0) {
        this.setRate(this.reserveRateBackup);
        this.reserveRateBackup = 0;
      } else if (this.reserve > 0) {
        var result = (this.reserveRateBackup + parseFloat(this.reserveRateBackup) * parseFloat(this.reserve)).toPrecision(3);
        this.setRate(result);
      }
    },
    minAmount: function minAmount() {
      this.resetAmountByMinAmount();
    },
    rate: function rate() {
      this.resetAmountByMinAmount();
    }
  },
  methods: {
    resetAmountByMinAmount: function resetAmountByMinAmount() {
      if (this.resetAmount) {
        this.amount = this.minAmount;
        this.rateCalc = this.smartRound(this.rate * this.minAmount);
      }
    },
    smartRound: function smartRound(val) {
      if (String(val).indexOf('.') !== -1) {
        val = val.toFixed(2);
        val = String(val).replace(/0$/, '');
        val = Number(val.replace(/.0$/, ''));
      }
      return val;
    },
    getAmount: function getAmount() {
      return this.amount < this.minAmount ? parseFloat(this.minAmount) : this.amount;
    },
    setRate: function setRate(rate) {
      this.rateInner = parseFloat(rate);
      this.calcAmount(this.rateCalc);
    },
    resetReserve: function resetReserve() {
      this.reserveRateBackup = 0;
      $('.js-init-later').val(0).change().selectric("refresh");
    },
    calcAmount: function calcAmount(inputRate) {
      var val = Math.ceil(inputRate / this.rate);
      this.amount = val < this.minAmount ? parseFloat(this.minAmount) : val;

      setTimeout(function () {
        $('.amount-basket').data('min-value', parseFloat(this.minAmount)).change();
      }, 150);
    },
    format: function format(price) {
      if (price > 0) return price.toLocaleString('ru-RU') + this.currencySuffix;

      return this.emptyPriceText;
    },
    formatPercent: function formatPercent(percent) {
      return percent + '%';
    },
    round: function round(a, b) {
      b = b || 0;
      return Math.round(a * Math.pow(10, b)) / Math.pow(10, b);
    }
  },
  mounted: function mounted() {
    var vm = this;
    this.$nextTick(function () {
      $(this.$el).find('select.js-init-later').selectric({
        disableOnMobile: false,
        nativeOnMobile: true
      }).on('change', function () {
        vm.reserve = parseFloat($(this).val());
      });
    });
  }
};

exports.default = PriceComponent;
/* eslint-enable */

},{}],21:[function(require,module,exports){
'use strict';

var _PriceComponent = require('./PriceComponent.js');

var _PriceComponent2 = _interopRequireDefault(_PriceComponent);

var _OffersListComponent = require('./OffersListComponent.js');

var _OffersListComponent2 = _interopRequireDefault(_OffersListComponent);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/* eslint-disable */
/**
 * Подключение в common.js
 *   import './modules/card-choose-offer.js';
 *
 * Пример подключения в шаблоне
 * <div id="js-card-price-app" class="product-card__controls-column" data-default-offer='<?=$model->getBasketObjectJson()?>' data-target-button=".js-cart-basket-button">

 * <offers-list-component :offers-list='<?=json_encode($offers)?>' inline-template>
 *  <div>
 *    <label class="item js-radio" v-bind:class="{'is-checked': isActive(offer.id)}" v-bind:data-size="offer.text"
 *           v-for="offer in offersList" @click="$root.offer = offer">{{offer.text}}</label>
 *  </div>
 * </offers-list-component>
 *
 * <price-component :price="this.offer.price" :old-price="this.offer.oldPrice" inline-template>
 * <div class="product-card__price-group">
 *   <div class="product-card__old-price" v-if="oldPrice">
 *     <span v-html="oldPriceFormatted"></span>
 *     <div class="product-card__discount" v-html="economyPercentFormatted"></div>
 *   </div>
 *   <div class="product-card__price" v-html="priceFormatted"><?php echo PriceHelper::price($model->getPrice(), ' <span class="currency"><span>₽</span></span>');?></div>
 * </div>
 * </price-component>
 *
 * </div>
 *
 */
window.initPriceApp = function () {
  var selector = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '#js-card-price-app';

  if (!$(selector).length) return;

  var cardPriceApp = new Vue({
    el: selector,
    components: {
      'price-component': _PriceComponent2.default,
      'offers-list-component': _OffersListComponent2.default
    },
    data: {
      offer: {},
      targetButton: '',
      targetFastButton: ''
    },
    mounted: function mounted() {
      this.$nextTick(function () {
        this.offer = JSON.parse(this.$el.getAttribute('data-default-offer'));
        this.targetButton = this.$el.getAttribute('data-target-button');
        this.targetFastButton = this.$el.getAttribute('data-target-fast-button');
        // $(this.$el).find('select.js-init-later').selectric({
        //   disableOnMobile: false,
        //   nativeOnMobile: true,
        // });
      });
    },
    watch: {
      //Обнавляем данные на кнопке купить
      offer: function offer(val, oldVal) {
        var data = $(this.targetButton).data();
        $.extend(data, val.basketData);
        $(this.targetButton).data(data);
        $(this.targetFastButton).data(data);
      }
    }
  });
};

window.initPriceApp('#' + $('.js-card-price-app').attr('id'));

/* eslint-enable */

},{"./OffersListComponent.js":19,"./PriceComponent.js":20}],22:[function(require,module,exports){
'use strict';

/* global Vue */

/**
 * Created by dev on 01.07.2022.
 * первичная глобальная инициализация полей в приложении Vue, срабатывает после создания приложения
 * инициализированные и присвоенные поля доступны в  "beforeUpdate: function () {}"  Vue обекта
 * пример <input v-init:depth="100" v-model.number="depth">
 *
 */
window.vueDirective = function () {
  Vue.directive('init', {
    bind: function bind(el, binding, vnode) {
      vnode.context[binding.arg] = binding.value;
    }
  });
};

window.vueDirective();

},{}]},{},[2]);
